"use client"

import { useEffect } from "react"
import { pdfjs } from "react-pdf"

export default function ReactPdf() {
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            "pdfjs-dist/build/pdf.worker.min.js",
            import.meta.url,
        ).toString()
    }, [])

    return null
}
